.slider
    &__item
        height: 531px
        background-position: center

        .grid
            position: relative
            justify-content: center
            height: 100%

    &__title
        font-size: rem-calc(40px)
        line-height: rem-calc(40px)
        font-weight: 700
        margin-bottom: 9px

    &__desc
        margin-bottom: 10px

    &__outline
        display: inline-block
        padding: 3px 5px
        background: color-yellow
        font-weight: 700
        font-size: rem-calc(23px)

    &__address
        display: flex
        align-items: center
        position: absolute
        left: 0
        bottom: 25px
        font-size: rem-calc(14px)
        line-height: 1

        &:before
            display: block
            content: ''
            margin-right: 6px
            width: 18px
            height: 18px
            background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNMjU2LDBDMTU2Ljc0OCwwLDc2LDgwLjc0OCw3NiwxODBjMCwzMy41MzQsOS4yODksNjYuMjYsMjYuODY5LDk0LjY1MmwxNDIuODg1LDIzMC4yNTcgICAgYzIuNzM3LDQuNDExLDcuNTU5LDcuMDkxLDEyLjc0NSw3LjA5MWMwLjA0LDAsMC4wNzksMCwwLjExOSwwYzUuMjMxLTAuMDQxLDEwLjA2My0yLjgwNCwxMi43NS03LjI5Mkw0MTAuNjExLDI3Mi4yMiAgICBDNDI3LjIyMSwyNDQuNDI4LDQzNiwyMTIuNTM5LDQzNiwxODBDNDM2LDgwLjc0OCwzNTUuMjUyLDAsMjU2LDB6IE0zODQuODY2LDI1Ni44MThMMjU4LjI3Miw0NjguMTg2bC0xMjkuOTA1LTIwOS4zNCAgICBDMTEzLjczNCwyMzUuMjE0LDEwNS44LDIwNy45NSwxMDUuOCwxODBjMC04Mi43MSw2Ny40OS0xNTAuMiwxNTAuMi0xNTAuMlM0MDYuMSw5Ny4yOSw0MDYuMSwxODAgICAgQzQwNi4xLDIwNy4xMjEsMzk4LjY4OSwyMzMuNjg4LDM4NC44NjYsMjU2LjgxOHoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yNTYsOTBjLTQ5LjYyNiwwLTkwLDQwLjM3NC05MCw5MGMwLDQ5LjMwOSwzOS43MTcsOTAsOTAsOTBjNTAuOTAzLDAsOTAtNDEuMjMzLDkwLTkwQzM0NiwxMzAuMzc0LDMwNS42MjYsOTAsMjU2LDkweiAgICAgTTI1NiwyNDAuMmMtMzMuMjU3LDAtNjAuMi0yNy4wMzMtNjAuMi02MC4yYzAtMzMuMDg0LDI3LjExNi02MC4yLDYwLjItNjAuMnM2MC4xLDI3LjExNiw2MC4xLDYwLjIgICAgQzMxNi4xLDIxMi42ODMsMjg5Ljc4NCwyNDAuMiwyNTYsMjQwLjJ6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==')
            background-size: cover


.intro
    padding: 80px 35px
    text-align: center

    &__title
        margin-bottom: 37px

    &__text
        margin-bottom: 30px

    &__btn
        margin: 0 auto


.futures
    padding: 70px 0 15px
    background: #f8f8f8

    &__ico
        display: block

        &wrap 
            max-width: 78px
            width: 78px
            margin-right: 20px

    &__inner
        max-width: 300px

    &__item
        display: flex
        margin-bottom: 53px

    &__title
        font-size: rem-calc(21px)
        line-height: rem-calc(27px)
        font-weight: 500
        margin-bottom: 3px


.news
    padding: 80px 0

    &__header
        text-align: center
        margin-bottom: 30px

    &__title
        margin-bottom: 5px

    &__more
        &_wrap
            text-align: center
            margin-top: 40px

    &__grid
        &-single
            .news__card
                margin-bottom: 30px

    &__card
        background: #f8f8f8

        &:hover
            .news__card_pic
                transform: scale(1.04)

        &_pic
            display: block
            transition: all 1s ease 

            &wrap
                display: block
                overflow: hidden
                height: 266px

        &_inner
            padding: 40px 30px 30px

        &_date
            color: #959595
            font-size: rem-calc(14px)
            line-height: rem-calc(27px)
            font-weight: 500
            margin-bottom: 10px

        &_title
            display: -webkit-box !important
            display: block
            color: #000000
            font-size: rem-calc(21px)
            line-height: rem-calc(28px)
            margin-bottom: 20px
            font-weight: 500

            -webkit-box-orient: vertical
            overflow-x: hidden
            overflow: hidden
            text-overflow: ellipsis
            max-height: 56px
            height: 56px
            -webkit-line-clamp: 2

            transition: all .35s ease

            &:hover
                color: color-blue

        &_text
            display: -webkit-box
            -webkit-box-orient: vertical
            overflow-x: hidden
            overflow: hidden
            text-overflow: ellipsis
            max-height: 150px
            height: 150px
            -webkit-line-clamp: 6

        &_more
            display: flex
            align-items: center
            margin-top: 25px
            color: color-blue
            font-weight: 500

            &:before
                display: block
                content: ''
                width: 18px
                height: 18px
                margin-right: 5px
                background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KICA8Zz4KICAgIDxnPgogICAgICA8cGF0aCBkPSJtNjQuNSwxMjIuNmMzMiwwIDU4LjEtMjYgNTguMS01OC4xcy0yNi01OC01OC4xLTU4LTU4LDI2LTU4LDU4IDI2LDU4LjEgNTgsNTguMXptMC0xMDhjMjcuNSw1LjMyOTA3ZS0xNSA0OS45LDIyLjQgNDkuOSw0OS45cy0yMi40LDQ5LjktNDkuOSw0OS45LTQ5LjktMjIuNC00OS45LTQ5LjkgMjIuNC00OS45IDQ5LjktNDkuOXoiIGZpbGw9IiMxODRlYTUiLz4KICAgICAgPHBhdGggZD0ibTcwLDkzLjVjMC44LDAuOCAxLjgsMS4yIDIuOSwxLjIgMSwwIDIuMS0wLjQgMi45LTEuMiAxLjYtMS42IDEuNi00LjIgMC01LjhsLTIzLjUtMjMuNSAyMy41LTIzLjVjMS42LTEuNiAxLjYtNC4yIDAtNS44cy00LjItMS42LTUuOCwwbC0yNi40LDI2LjRjLTAuOCwwLjgtMS4yLDEuOC0xLjIsMi45czAuNCwyLjEgMS4yLDIuOWwyNi40LDI2LjR6IiBmaWxsPSIjMTg0ZWE1Ii8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K')
                background-size: cover
                transform: scale(-1, 1)


.partners
    margin-bottom: 80px

    &__caro
        padding: 0 30px

        &_pic
            width: auto !important
            max-width: 100%
            max-height: 84px
            padding: 0 30px
            //- margin: 0 auto

        .owl-nav
            width: 100%
            margin: 0


.uptop
    display: none
    align-items: center
    justify-content: center
    position: fixed
    bottom: 30px
    right: 40px
    width: 50px
    height: 43px
    background: color-blue
    border-radius: 6px

    &.fix
        display: flex

    &:before
        display: block
        content: ''
        width: 19px
        height: 12px
        background: url('../img/top.png') no-repeat center
        background-size: cover
        


.page__inner ul li {
    position: relative;
    list-style-type: none;
    padding-left: 20px;
}
.page__inner ul li:before {
    display: block;
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 100%;
    top: 10px;
    left: 5px;    
}