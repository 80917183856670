@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .row {
        flex: 0 1 auto !important;    
    }
    .footer {
        min-height: 787px
    }
}

@media screen and (min-width: 1000px) {
    .header__top_link {
        text-align: center;
    }    
    .header__search {
        display: block;
    }
}

@media screen and (max-width: 1000px) {
    .header__search {
        display: none;
    }    
    .header__inner {
        margin-left: 0;    
    }
}

@media screen and (min-width: 760px) and (max-width: 1200px) {
    .slider__inner {
        padding: 0 60px;
    }
    .slider__address {
        left: 60px;    
    }
}

@media screen and (max-width: 1200px) {
    .header {
        width: 100%
        left: 0
        transform: none
    }
    .main, .footer {
        width: 100%    
    }
    .header__logo_desc {
        font-size: rem-calc(14px);    
    }
    .header__menu_list {
        flex-wrap: wrap;
    }
    .intro__text br {
        display: none;
    }
    .page__banner_pic {
        max-width: 100%;    
    }
    .page__gallery_item {
        height: 150px;
    }
    .header__top_link {
        text-align: left;
    }
}

@media screen and (max-width: 768px) {
    .sv_settings {
        padding: 260px 10px 10px !important;
    }
    .mgl20 {
        margin-bottom: 15px;    
    }

    .header__menu {
        display: none;
    }
    .toggle {
        display: flex;    
    }
    .header__main {
        padding: 20px 0;    
    }
    .header__inner {
        flex-direction: column;    
        border: 1px solid color-blue;
    }
    .header__top_font, .header__logo {
        justify-content: center;    
    }
    .header__menu {
        width: 100%;
        padding: 10px 0;
        margin-right: 0;
    }
    .header__menu_item {
        width: 100%;    
    }
    .header__menu_list {
        flex-direction: column;    
        margin: 0;
    }
    .header__menu_item.active .header__menu_link {
        border-radius: 0;
    }
    .header__menu_link {
        padding: 20px 20px;  
        text-align: center;  
    }
    .header__top {
        text-align: center;    
    }
    .header__submenu {
        z-index: 100;
    }
    .header.fix .header__main {
        padding-top: 0;    
    }
    .header.fix .header__logo {
        display: none;    
    }
    .slider__address {
        bottom: 50px;    
        left: 50px;
    }
    .slider__inner {
        padding: 10px 50px;
        background: rgba(255, 255, 255, .5);
    }
    .intro, .futures, .news {
        padding: 50px 0px;
    }
    h2 {
        font-size: 38px;
        margin-bottom: 20px !important;
    }
    .news__card_pic {
        width: 100%;    
    }
    .footer__socials {
        justify-content: flex-start;    
    }
    .page__inner {
        padding-right: 0;    
    }
    .page__gallery_item {
        width: calc((100%/2) - 14px);
    }
    .page__banner_pic {
        display: block;
        margin: 0 auto;
    }
    .header__top_link {
        text-align: center !important;
    }    
    .slider__item {
        background-position: center right
    }
}

//- VDS
.fs__col {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;    
}
.sv_settings{
    display: none;
    padding: 10px;
    min-height: 40px;
    font-size:22px;
    color:#333;
    background: white;
    padding-top: 200px;
}
.sv_settings *{
    font-weight:bold;
}
.fs-outer button{
    display:inline-block;  
    background:white !important;
    color:black !important; 
    height:35px;
    width:35px;
    padding: 0;
}
.fs-n{
    font-size:14px !important;
}
.fs-m{
    font-size:16px !important;
}
.fs-l{
    font-size:17px !important;
}
.cs-outer button{
    display:inline-block;  
    height:35px;
    width:35px;
    padding: 0;
}
.cs-bw, .cs-bw:focus, .cs-bw:hover{
    color:black !important;
    background:white !important;
}
.cs-wb, .cs-wb:focus, .cs-wb:hover{
    color:white !important;
    background:black !important;
}
.cs-bb, .cs-bb:focus, .cs-bb:hover{
    color: #063462 !important;
    background: #9dd1ff !important;
}
.cs-gb, .cs-gb:focus, .cs-gb:hover{
    color: #4d4b43 !important;
    background: #f7f3d6 !important;
}
.cs-yg, .cs-yg:focus, .cs-yg:hover{
    color: #a9e44d !important;
    background: #3b2717 !important;
}
#img-onoff {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #e8e8e8;
    margin-left: 10px;
}
#img-onoff img {
    display: block;
    margin-left: 5px;    
}
.img-inner {
    display: flex;
    align-items: center;
}