@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Black/ProximaNova-Black.eot?') format('eot'),
	     url('../fonts/ProximaNova-Black/ProximaNova-Black.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Black/ProximaNova-Black.woff') format('woff'),
	     url('../fonts/ProximaNova-Black/ProximaNova-Black.ttf')  format('truetype'),
	     url('../fonts/ProximaNova-Black/ProximaNova-Black.svg#ProximaNova-Black') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Bold/ProximaNova-Bold.eot?') format('eot'),
	     url('../fonts/ProximaNova-Bold/ProximaNova-Bold.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Bold/ProximaNova-Bold.woff') format('woff'),
	     url('../fonts/ProximaNova-Bold/ProximaNova-Bold.ttf')  format('truetype'),
	     url('../fonts/ProximaNova-Bold/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Extrabld/ProximaNova-Extrabld.eot?') format('eot'),
	     url('../fonts/ProximaNova-Extrabld/ProximaNova-Extrabld.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Extrabld/ProximaNova-Extrabld.woff') format('woff'),
	     url('../fonts/ProximaNova-Extrabld/ProximaNova-Extrabld.ttf')  format('truetype'),
	     url('../fonts/ProximaNova-Extrabld/ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Light/ProximaNova-Light.eot?') format('eot'),
	     url('../fonts/ProximaNova-Light/ProximaNova-Light.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Light/ProximaNova-Light.woff') format('woff'),
	     url('../fonts/ProximaNova-Light/ProximaNova-Light.ttf')  format('truetype'),
	     url('../fonts/ProximaNova-Light/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Regular/ProximaNova-Regular.eot?') format('eot'),
	     url('../fonts/ProximaNova-Regular/ProximaNova-Regular.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Regular/ProximaNova-Regular.woff') format('woff'),
	     url('../fonts/ProximaNova-Regular/ProximaNova-Regular.ttf')  format('truetype'),
	     url('../fonts/ProximaNova-Regular/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@import '_normalize.styl'
@import '_vars.styl'
@import '_mixins.styl'
@import 'grid/flexboxgrid.styl'

//- Стандартизация
@import 'common/_elements.styl'

//- Глобальные блоки
@import 'common/_header.styl'
@import 'common/_footer.styl'
@import 'common/_modals.styl'

//- ================
//- Элементы страниц
//- ================
@import "blocks/_ui_owl.styl"
	
//- ================
//- Стили страниц
//- ================
@import 'pages/_index.styl'
@import 'pages/_pages.styl'

//- ================
//- Адаптив
//- ================
@import "common/_responsive.styl"