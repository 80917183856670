@import '../bootstrap.styl'

.row
  box-sizing: border-box
  display: flex
  flex: 0 1 100%
  flex-direction: row
  flex-wrap: wrap
  list-style: none
  margin: (-($gutter['height'])) (-($gutter['width'] / 2)) $gutter['height']
  padding: 0

  &:last-child
    margin-bottom: 0

  +variant('no-gutters')
    margin: 0

  +variant('top')
    align-items: flex-start

  +variant('center')
    align-items: center

  +variant('bottom')
    align-items: flex-end

  +variant('reverse')
    flex-direction: row-reverse

make-rows($breakpoints)
