// Will make a single grid row.
make-row($breakpoint)
  .row
    +variant($breakpoint + '-no-gutters')
      margin: 0

    +variant($breakpoint + '-top')
      align-items: flex-start

    +variant($breakpoint + '-center')
      align-items: center

    +variant($breakpoint + '-bottom')
      align-items: flex-end

    +variant($breakpoint + '-reverse')
      flex-direction: row-reverse

// Will make the grid rows.
make-rows($breakpoints)
  for $breakpoint, $width in $breakpoints
    if $width == 0
      make-row($breakpoint)
    else
      @media (min-width: $width)
        make-row($breakpoint)
