.footer
    width: 1172px
    margin: 0 auto
    padding: 60px 0 20px
    background: color-blue

    &__logo
        display: flex
        align-items: center
        color: #ffffff

        &_pic
            display: block
            margin-right: 15px

        &_desc
            display: block
            font-size: rem-calc(15px)
            line-height: rem-calc(18px)
            font-weight: 500

    &__contact
        &_item
            display: flex
            align-items: center
            margin-bottom: 11px
            transition: all .35s ease 

            &:hover
                opacity: .65

            &:last-child
                margin-bottom: 0

        &_pic
            display: block

            &wrap
                display: block
                margin-right: 15px
                width: 24px

        &_text
            display: block
            color: #ffffff
            font-size: rem-calc(14px)
            line-height: rem-calc(18px)

    &__socials
        display: flex
        align-items: center
        justify-content: flex-start

        &_item
            margin-right: 30px
            transition: all .35s ease 

            &:hover
                opacity: .65

            &:last-child
                margin-right: 0


    &__main
        padding: 65px 0

    &__links
        display: block

        &_item
            display: block
            transition: all .35s ease 

            &:hover
                opacity: .65

            &:last-child
                margin-bottom: 0

        &_link
            display: block
            color: #ffffff
            font-size: 14px

            &-header
                font-weight: 500

    &__bottom
        text-align: center
        font-size: rem-calc(16px)
        line-height: rem-calc(21px)
        text-transform: uppercase
        color: #ffffff

