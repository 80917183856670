.fancybox-slide
  text-align: left

.ui__modal
  display: none
  left: 50%
  background: #FFFFFF
  padding: 40px 26px
  text-align: left
  width: 554px
  max-width: 554px
  margin: 0 auto
  position: relative
  box-shadow: 0 26px 70px rgba(24, 31, 81, .07)
  transform: translateX(-50%)

  &_wide
    padding: 112px 50px
    width: 620px
    height: 100%
    left: 0
    top: 0
    transform: none

    .ui__modal_title
      line-height: rem-calc(45px)
      margin-bottom: 30px

    .ui__modal_group
      margin-bottom: 20px

      &:last-child
        margin-bottom: 0

    .ui__modal_label
      line-height: rem-calc(28px)
      margin-bottom: 16px


  &_title
    display: block
    font-size: rem-calc(30px)
    line-height: rem-calc(32px)
    font-weight: 700
    margin-bottom: 16px

  &_desc
    font-size: rem-calc(15px)
    margin-bottom: 30px

  &_center
    text-align: center
    padding: 120px 0 92px

  &_counter
    position: absolute
    bottom: 52px
    left: 50%
    font-size: rem-calc(13px)
    line-height: rem-calc(18px)
    transform: translateX(-50%)


  &_pretitle
    margin-bottom: 12px

  &_region
    display: flex
    align-items: center
    position: relative

    &:before
      display: block
      content: ''
      width: 12px
      height: 17px
      margin-right: 10px
      background: url('../img/pin.svg') no-repeat center
      background-size: cover



  &_case
    width: 1121px
    max-width: 1121px
    padding: 0

    &inner 
      display: flex
      height: 100%

  &_header
    position: relative
    height: 327px
    color: #ffffff
    padding: 86px 45px 25px

    &inner 
      position: absolute
      left: 45px
      bottom: 25px
      z-index: 10

  &_pic
    max-width: 100%
    
    &wrap
      display: block
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      overflow: hidden
      z-index: 1

      &:before
        display: block
        content: ''
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: rgba(color-blue, .84)
        z-index: 3



  &_left
    position: relative
    width: 50%
    max-height: 100vh
    overflow: hidden
    flex-grow: 1

  &_right
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 26px
    width: 50%
    flex-grow: 1
    background: #F7F7F7


  &_content
    padding: 26px 50px

.ui__content
  &_title
    font-size: rem-calc(24px)
    font-weight: 600
    margin-bottom: 16px


  p
    display: block
    margin-bottom: 30px





button.mfp-close
  background-image: url('../img/cross.svg')
  background-repeat: no-repeat
  background-position: center

.mfp-close
  width: 20px
  height: 20px
  right: 20px
  top: 20px
  padding: 0
  content: ''
  opacity: 1
  transition: all .25s ease

  &:active
    top: 20px

  &:hover
    opacity: .8


.fancybox-bg
   background: rgba(#001647, .91)



.fancybox-close-small:after
  content: '' !important
  background: url('../img/close_1.svg') no-repeat center !important
  background-size: cover
  width: 14px
  height: 14px
  top: 50%
  left: 50%
  right: 0
  border-radius: unset
  transform: translate(-50%, -50%)

.fancybox-close-small:hover:after
    color: inherit
    background: inherit



.mfp-bg
  opacity: 1 !important
  background: linear-gradient(125.72deg, rgba(81, 115, 178, 0.8) 0.3%, rgba(32, 32, 97, 0.8) 99.33%)

.my-mfp-zoom-in .zoom-anim-dialog
  opacity: 0
  transition: all 0.2s ease-in-out
  transform: scale(0.8)

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog
  opacity: 1
  transform: scale(1)

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog
  transform: scale(0.8)
  opacity: 0

.my-mfp-zoom-in.mfp-bg
  opacity: 0
  transition: opacity 0.3s ease-out

.my-mfp-zoom-in.mfp-ready.mfp-bg
  opacity: 0.8

.my-mfp-zoom-in.mfp-removing.mfp-bg
  opacity: 0