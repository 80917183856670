.header
    position: fixed
    top: 0
    left: 50%
    width: 1172px
    margin: 0 auto
    background: #ffffff
    transform: translateX(-50%)
    z-index: 100

    &.fix
        .header__main
            padding: 10px 0

    &__top
        padding: 8px 0
        background: #959595
        min-height: 30px

        &_link
            display: block
            font-size: rem-calc(14px)
            line-height: 1
            color: #ffffff

        &_font
            display: flex
            position: relative
            align-items: end
            justify-content: flex-end
            color: color-yellow
            font-weight: 500
            font-size: rem-calc(14px)
            line-height: 1

            &:before
                display: block
                content: ''
                width: 19px
                height: 12px
                margin-right: 13px
                background: url('../img/full_view.png') no-repeat center
                background-size: cover


    &__main
        padding: 37px 0
        border-bottom: 1px solid #d8dbdc
        transition: all .15s ease

        .row
            align-items: center

    &__logo
        display: flex
        align-items: center
        color: #000000

        &_pic
            display: block
            margin-right: 15px

        &_desc
            display: block
            font-size: rem-calc(15px)
            line-height: rem-calc(18px)
            font-weight: 500

    &__menu
        flex-grow: 1
        margin-right: 15px

        &_list
            display: flex
            align-items: center
            margin: 0 -15px

        &_item
            display: block
            position: relative

            &.active .header__menu_link
                background: color-yellow

            &:hover 
                .header__menu_link
                    color: color-blue

                .header__submenu
                    display: block
                
        &_link
            display: block
            padding: 8px 15px
            border-radius: 10px
            font-size: rem-calc(15px)
            line-height: 1
            color: #000000
            transition: all .35s ease

    &__submenu
        display: none
        position: absolute
        top: 30px
        left: 50%
        width: 370px
        max-width: 370px
        padding-top: 16px
        transform: translate(-50%)

        &:before
            display: block
            content: ''
            position: absolute
            width: 0
            height: 0
            top: 7px
            left: 50%
            transform: translateX(-50%)
            border-left: 6px solid transparent
            border-right: 6px solid transparent
            border-bottom: 10px solid color-blue

        &_list
            display: block
            background: color-blue
            padding: 24px 27px

        &_item
            display: block
            margin-bottom: 18px

            &:last-child
                margin-bottom: 0

        &_link
            display: block
            font-size: rem-calc(16px)
            line-height: rem-calc(20px)
            color: #ffffff
            transition: all .35s ease

            &:hover
                color: color-yellow

    &__search
        display: block
        position: relative
        max-width: 100px
        transition: all .35s ease

        &.wide
            position: absolute
            max-width: unset 
            width: 100%

        &_field
            display: inline-block
            background: #e8e8e8
            width: 100%
            border-radius: 10px
            font-size: rem-calc(14px)
            line-height: rem-calc(24px)
            padding: 6px 12px 5px 40px
            border: 1px solid #e8e8e8
            transition: all .35s ease 
            z-index: 3

            placeholder()
                color: #747473

        &_btn
            display: block
            position: absolute
            cursor: pointer
            top: 50%
            left: 14px
            width: 13px
            height: 13px
            background: url('../img/search.png') no-repeat center
            background-size: cover
            transform: translateY(-50%)
            z-index: 5

    &__inner
        display: flex
        align-items: center
        position: relative
        margin-left: -30px

.toggle
    display: none
    position: relative;
    color: #ffffff
    padding: 10px
    width: 100%
    background: color-blue
    align-items: center;
    justify-content: center

    &:before
        display: block
        content: ''
        width: 24px
        height: 24px
        background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDM0NC4zMzkgMzQ0LjMzOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQ0LjMzOSAzNDQuMzM5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGc+CgkJPGc+CgkJCTxyZWN0IHk9IjQ2LjA2IiB3aWR0aD0iMzQ0LjMzOSIgaGVpZ2h0PSIyOS41MiIgZmlsbD0iI0ZGRkZGRiIvPgoJCTwvZz4KCQk8Zz4KCQkJPHJlY3QgeT0iMTU2LjUwNiIgd2lkdGg9IjM0NC4zMzkiIGhlaWdodD0iMjkuNTIiIGZpbGw9IiNGRkZGRkYiLz4KCQk8L2c+CgkJPGc+CgkJCTxyZWN0IHk9IjI2OC43NDgiIHdpZHRoPSIzNDQuMzM5IiBoZWlnaHQ9IjI5LjUzMSIgZmlsbD0iI0ZGRkZGRiIvPgoJCTwvZz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K')
        background-size: cover
        margin-right: 10px


.header:not(.fix) .header__main
    min-height: 140px

.header.fix .header__main
    min-height: 88px