.owl
  &-nav
    display: flex
    position: absolute
    justify-content: space-between
    top: calc(50% - 37px)
    left: 0
    margin: 0 18px
    width: calc(100% - 30px)

  &-prev, &-next
    display: flex
    align-items: center
    justify-content: center
    position: relative
    height: 54px
    background: color-green
    transition: .25s all linear
    z-index: 1

    &:after
      display: block
      position: relative
      content: ''
      width: 24px
      height: 24px
      background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KICA8Zz4KICAgIDxnPgogICAgICA8cGF0aCBkPSJtNjQuNSwxMjIuNmMzMiwwIDU4LjEtMjYgNTguMS01OC4xcy0yNi01OC01OC4xLTU4LTU4LDI2LTU4LDU4IDI2LDU4LjEgNTgsNTguMXptMC0xMDhjMjcuNSw1LjMyOTA3ZS0xNSA0OS45LDIyLjQgNDkuOSw0OS45cy0yMi40LDQ5LjktNDkuOSw0OS45LTQ5LjktMjIuNC00OS45LTQ5LjkgMjIuNC00OS45IDQ5LjktNDkuOXoiIGZpbGw9IiMxODRlYTUiLz4KICAgICAgPHBhdGggZD0ibTcwLDkzLjVjMC44LDAuOCAxLjgsMS4yIDIuOSwxLjIgMSwwIDIuMS0wLjQgMi45LTEuMiAxLjYtMS42IDEuNi00LjIgMC01LjhsLTIzLjUtMjMuNSAyMy41LTIzLjVjMS42LTEuNiAxLjYtNC4yIDAtNS44cy00LjItMS42LTUuOCwwbC0yNi40LDI2LjRjLTAuOCwwLjgtMS4yLDEuOC0xLjIsMi45czAuNCwyLjEgMS4yLDIuOWwyNi40LDI2LjR6IiBmaWxsPSIjMTg0ZWE1Ii8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K')
      background-size: cover
      transform: scale(-1, 1)
      z-index: 5

    &.disabled
      opacity: .5
      cursor: not-allowed !important
      pointer-events: all !important

  &-prev
    transform: scale(-1, 1) !important


  &-dots
    display: flex
    align-items: center
    position: absolute
    left: 50%
    bottom: 25px
    transform: translateX(-50%)

  &-dot
    display: block
    width: 10px
    height: 10px
    margin: 0 7px
    border: 2px solid #ffffff
    border-radius: 100%

    &:first-child
      margin-left: 0
    &:last-child
      margin-right: 0

    &.active
      background: #ffffff


.ui__owl
  display: flex
  position: relative

  &_slider
    margin-bottom: 30px

  &_item
    position: relative
    transform: scale(1)
    padding: 11px 0
    z-index: 1
    transition: all .3s ease

    &:hover
      z-index: 3
      transform: scale(1.05)

  &_pic
    &_wrap
      display: block
      position: relative

  &_title
    display: block
    text-align: center
    font-family: 'Akrobat', sans-serif
    margin-top: 12px

  &_numb
    display: flex
    align-items: center
    position: absolute
    bottom: 0
    right: 0

    &_cur
      color: color-blue
      font-size: rem-calc(25px)
      margin-right: 7px

  &_state
    position: absolute
    left: 0
    bottom: 0
    height: 2px
    margin: 10px 0
    width: 100%
    max-width: 718px
    background: color-light

  &_progress
    position: absolute
    background: color-dark
    height: 100%
    top: 0
    left: 0
    transition: all .25s ease

  &-numb
    padding-bottom: 40px

    .owl-nav
      right: 60px
      height: 30px
      width: 47px

  .ui__owl_state
    max-width: calc(100% - 130px)

  .owl-stage
    padding-left: 0 !important