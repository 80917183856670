.aside
    margin-top: 36px

.breadcrumbs
    display: flex
    flex-wrap: wrap

    &__item
        display: flex
        align-items: center
        margin-right: 6px
        font-size: rem-calc(14px)
        line-height: rem-calc(32px)

        &:hover a.breadcrumbs__link
            color: color-blue

        &:after
            display: block
            content: '>'
            color: #959595
            margin-left: 5px

        &:last-child:after
            display: none

    &__link
        color: #959595
        transition: all .35s ease



.page
    &__banner
        display: block
        margin-bottom: 20px

        &_pic
            max-width: 100%


    &__gallery
        display: flex
        flex-wrap: wrap
        margin-top: 60px
        margin-left: -7px
        margin-right: -7px

        &_item
            overflow: hidden
            width: calc((100% / 3) - 14px)
            height: 200px
            margin: 0 7px 14px

            &:hover
                .page__gallery_pic
                    transform: scale(1.08)

        &_pic
            max-height: 100%
            transition: all 1s ease

    &__info
        color: color-blue
        font-size: rem-calc(14px)
        line-height: rem-calc(32px)
        margin-bottom: 18px

    &__content
        margin: 30px 0 80px

        .breadcrumbs
            margin-bottom: 5px

        ul:not(:last-child):not(.breadcrumbs)
            margin-bottom: 30px

    &__inner
        padding-right: 54px

    &__map
        margin-top: 60px

    &__title
        display: block
        margin-bottom: 18px

        &-nomargin
            margin-bottom: 0

    &__btn
        text-align: center
        margin: 55px 0 60px

    &__link
        display: inline-block
        color: #000
        text-decoration: underline
        text-decoration-color: rgba(color-blue, .5)
        font-weight: 500
        transition: all .15s ease 

        &:hover
            color: color-blue
            text-decoration-color: transparent

    &__menu
        display: block
        background: #f8f8f8
        padding: 24px 36px
        margin-bottom: 25px
        font-size: rem-calc(14px)
        line-height: rem-calc(21px)

        &_item
            margin-bottom: 5px

            &:last-child
                margin-bottom: 0

            &.active .page__menu_link
                color: color-blue

                &:before
                    background: color-blue


        &_link
            display: flex
            align-items: center
            position: relative
            padding-left: 11px
            color: #000000
            transition: all .35s ease

            &:hover
                color: color-blue

            &:before
                display: block
                content: ''
                position: absolute
                width: 4px
                height: 4px
                top: 9px
                left: 0
                border-radius: 100%
                background: #000000
                margin-right: 4px

            &-header
                font-weight: 500
                padding-left: 0

                &:before
                    display: none
                
            
    &__news
        &_card
            margin-bottom: 25px

            &:last-child
                margin-bottom: 0

        &_more
            display: block
            color: color-blue
            margin-top: -10px
            text-align: center


    &__documents
        &_group
            &:not(:last-child)
                margin-bottom: 40px

        &_title
            margin-bottom: 10px