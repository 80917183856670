@import '../bootstrap.styl'

.grid
  box-sizing: border-box
  display: flex
  flex-direction: column
  padding: 0 ($gutter['width'] / 2)

  +variant('container')
    margin: 0 auto
    max-width: 1030px
    width: 100%

  +variant('no-gutters')
    padding: 0

make-grids($breakpoints)
