*
  box-sizing border-box
  margin 0
  padding 0

body
  display: flex
  flex-direction: column
  font-family: base-font-family
  color: color-main
  background: #e8e8e8
  line-height: base-line-height

html
  font-size: base-font-size

body, html
  padding: 0
  border: 0
  vertical-align: baseline
  height: auto
  width: 100%
  min-height: 100%

//- Выделение
::-moz-selection
  background: base-selection-color
  color: white
  text-shadow: none

::selection
  background: base-selection-color
  color: white
  text-shadow: none

// browsehappy
.browsehappy
  display: block
  margin: 0
  padding: 30px
  text-align: center
  background: color-red
  color: #FFFFFF
  font-size: rem-calc(18px)
  line-height: rem-calc(22px)

  a, a:hover
    color: #FFFFFF
    text-decoration: underline


//- Заголовки
h1, h2, h3, h4, h5, h6
  margin: 0

h1
  font-size: rem-calc(35px)
  line-height: rem-calc(45px)
  font-weight: normal

h2
  font-size: rem-calc(25px)
  line-height: rem-calc(32px)
  font-weight: normal

h3
  font-size: rem-calc(24px)
  line-height: rem-calc(30px)

a
  text-decoration: none

p > strong
  font-weight: 500

//- Списки
ul, li
  margin 0
  padding 0
  list-style-type none

//- Кнопки
button
  box-shadow: none
  border: none

.ui__btn
  cursor: pointer
  padding: 12px 10px
  outline: none
  border-radius: 10px
  color: #000000
  background: color-yellow
  line-height: rem-calc(20px)
  text-align: center
  transition: all .45s ease
  
  &:hover
    background: color-blue
    color: #ffffff

  &.disabled
    background: #eee
    border-color: #ddd
    cursor: not-allowed
    pointer-events: all !important

    &:hover
      background: #eee
      color: color-main

  &-transparent
    background: color-bg
    color: #ffffff
    box-shadow: none !important

    &:hover
      background: #04112F


//- Текст
sup
  vertical-align: super

p:not(:last-child)
  margin-bottom: 30px

blockquote
  display: block
  padding: 40px
  border: 3px solid color-red
  background: #FFFFFF
  box-shadow: 0 26px 70px rgba(24, 31, 81, .07)
  font-size: rem-calc(24px)
  line-height: rem-calc(40px)
  margin-top: 22px


//- Формы
input, textarea, button
  outline none

input
  line-height: initial

  &.error, &:focus.error
    border-color: color-red

input, textarea
  background: #FFFFFF
  border: none
  line-height: 1
  transition: all .25s ease
  -webkit-appearance: none

  +placeholder()
    color: #747473

input[type="search"]
  -webkit-appearance: none

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
  -webkit-appearance: none

textarea
  width 100%
  resize none

  &.error, &:focus.error
    border-color: color-red

fieldset
  border none
  margin: 0
  padding: 0

.ui__form
  &_small
    text-align: center
    font-size: rem-calc(13px)
    line-height: rem-calc(18px)
    margin-top: 24px

.ui__input
  &_wrap
    display: block
    position: relative
    margin-bottom: 10px

    &:last-child
      margin-bottom: 0

    &-gray
      .ui__input_field
        background: #F7F7F7

  &_field
    border: 2px solid #ffffff
    padding: 24.5px 25px 7.5px
    width: 100%
    color: #000000
    transition: all .35s ease
    z-index: 1

.ui__input_field.noempty+.ui__input_label
  top: 8.5px
  font-size: rem-calc(13px)
  line-height: 1

.ui__input_field:focus+.ui__input_label
  top: 8.5px
  font-size: rem-calc(13px)
  line-height: 1

.ui__input_label
    display: block
    position: absolute
    cursor: text
    color: #9B9B9B
    left: 25px
    top: 14px
    transition: all .35s ease
    z-index: 5



//- Прочее
.main
  width: 1172px
  margin: 172px auto 0
  background: #ffffff
  flex-grow: 1


.grid
  &.no-padding
    padding: 0

    .row
      margin-left: 0
      margin-right: 0

    .col
      padding-left: 0
      padding-right: 0


.row-center
  align-items: center

.fancybox-container
  z-index: 500

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}
